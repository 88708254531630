:root {
  touch-action: pan-x pan-y;
  height: 100%
}

.padd {
  padding: 25px 35px !important
}


.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.video-background video {
  width: 100%;
  height: auto;
  min-height: 100%;
}

.video-background .video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
}

.jumbotron {
  margin-top: 15px;
  position: relative;
  width: 100%;
  background-position: center center;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  overflow: hidden;
  background: #000;
  z-index: 1;
  color: #fff;
  -webkit-animation: 10s linear 0s normal none infinite animate;
  -moz-animation: 10s linear 0s normal none infinite animate;
  -ms-animation: 10s linear 0s normal none infinite animate;
  -o-animation: 10s linear 0s normal none infinite animate;
  animation: 10s linear 0s normal none infinite animate;
}




.jumbotron {
  position: relative;
  overflow: hidden;
  padding: 0;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;


}



.lp_avatar {
  width: 100px;
  height: 100px;
  border: 2px solid #000;
  border-radius: 50%;
}




body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff !important;
}

html {
  background-color: #fff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar-item {
  color: #000;
  font-weight: 600;
  font-size: 1.1rem;
}



.destra {
  text-align: right;
}

.marginmob {
  margin-top: 0px
}

@media screen and (max-width: 1024px) {

  .marginmob {
    margin-top: 50px
  }

  .video-background video {
    width: auto;
    height: 100%;
    min-height: inherit;
    max-width: unset;
  }


  .padd {
    padding: 15px 20px !important
  }


  .navbar-item {
    color: #fff !important;

  }

  .mobonly {
    display: block !important;
  }

  .columns {
    margin-left: 0;
    margin-right: 0;
  }

  .destra {
    text-align: left;
  }

  .boldme {
    color: #fff !important;
  }



}

.boldme {
  color: #FECB00;
}




.navbar-item img {
  max-height: unset;
}

.logonav {
  height: 30px;
}

.logonav2 {
  height: 50px;
}



.colore {
  color: #fff;
}

.navbar {
  padding-top: 5px;
  background-color: fff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .05);

}

h1 {

  color: #000 !important;
  font-size: 1.4rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#nav-toggle-state {
  display: none;
}

#nav-toggle-state:checked~.navbar-menu {
  display: block;
}

.burger,
.columns {
  color: #3C2200;
}

.myInputClass,
.myLabelClass {
  border: 1px solid gray;
  padding: 10px 10px;
}

.select,
select {
  width: 100%;
}

.navbar-burger span {
  height: 2px;
  width: 20px;
}

.boldme {
  color: #FECB00;
}

.morebtn {
  -webkit-animation: pulse2 1.3s infinite;
  animation: pulse2 1.3s infinite;
  border-radius: 50%;
}

@-webkit-keyframes pulse2 {
  0% {
    /*box-shadow: 0 0 0 0 rgba(255, 200, 80, 0.5);*/
    box-shadow: 0 0 0 0 rgba(58, 187, 103, 0.5)
  }

  65% {
    /* box-shadow: 0 0 0 10px rgba(255, 200, 80, 0); */
    box-shadow: 0 0 0 10px rgba(58, 187, 103, 0);
  }

  to {
    /*box-shadow: 0 0 0 0 rgba(255, 200, 80, 0);*/
    box-shadow: 0 0 0 0 rgba(58, 187, 103, 0);

  }
}

@keyframes pulse2 {
  0% {

    box-shadow: 0 0 0 0 rgba(58, 187, 103, 0.5)
  }

  65% {
    box-shadow: 0 0 0 10px rgba(58, 187, 103, 0);
  }

  to {
    box-shadow: 0 0 0 0 rgba(58, 187, 103, 0);
  }
}

.footlink {
  color: #fff;
  font-weight: 600;
}

.footlink:hover {
  color: #FECB00;
}

.modal {
  display: flex;
  color: black;
}

.modal-background {
  background-color: rgba(10, 10, 10, 0.56);
}

.Toastify__toast--info {
  background: #3174df !important;
}


.bottombar {
  /* background-color: #333; */
  background-color: #F883A4;

  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 65px;
  margin: 0 !important;
  text-align: center;
  margin-bottom: -1px !important;
  z-index: 9999999999;
  font-size: 0.9em;
  box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.15);

}

.fakebtn {
  all: unset !important;

}

.lolcol {



  width: 170px !important;
  /* Can be in percentage also. */
  height: auto;
  margin: 0 5px;
  padding: 10px;
  position: relative;
  background-color: white;


}


.lolcol2 {



  height: auto;
  margin: 0;
  padding: 10px;
  position: relative;
  background-color: white;


}


#ReactSimpleImageViewer {
  z-index: 9;
}

.pricecols {

  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}


.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}


h3 {
  color: #3C2200 !important
}

p strong {
  color: #3C2200 !important
}


.slick-list {
  overflow-x: scroll !important;

}

.msgimg {

  object-fit: cover;
  width: 200px;
  height: 200px;
  border-radius: 3px;
}

.avatar {
  /* This image is 687 wide by 1024 tall, similar to your aspect ratio */

  /* make a square container */
  width: 100px !important;
  height: 100px !important;

  /* fill the container, preserving aspect ratio, and cropping to fit */
  background-size: cover;

  /* center the image vertically and horizontally */
  background-position: top center;

  /* round the edges to a circle with border radius 1/2 container size */
  border-radius: 50%;
}

.avatar2 {
  /* This image is 687 wide by 1024 tall, similar to your aspect ratio */

  /* make a square container */
  width: 70px !important;
  height: 70px !important;

  /* fill the container, preserving aspect ratio, and cropping to fit */
  background-size: cover;

  /* center the image vertically and horizontally */
  background-position: top center;

  /* round the edges to a circle with border radius 1/2 container size */
  border-radius: 50%;
}

.avatar3 {
  /* This image is 687 wide by 1024 tall, similar to your aspect ratio */

  /* make a square container */
  width: 40px !important;
  height: 40px !important;

  /* fill the container, preserving aspect ratio, and cropping to fit */
  background-size: cover;

  /* center the image vertically and horizontally */
  background-position: top center;

  /* round the edges to a circle with border radius 1/2 container size */
  border-radius: 50%;
  vertical-align: middle;
  display: inline-block;
}





.swipe {
  position: absolute;
}




.activee {
  animation: fadein 2s;
  animation-delay: 0s;

}

@keyframes fadein {

  0% {
    opacity: 0
  }

  70% {
    opacity: 0
  }

  100% {
    opacity: 1
  }


}



.cardContainer {
  width: 90vw;
  max-width: 300px;
  height: 300px;
  margin: auto;
  overflow: hidden
}

.card2 {
  text-align: center;
  margin: 0 auto;
  position: relative;
  width: 80vw;
  max-width: 300px;
  height: 300px;
  border-radius: 20px;
  background-size: cover;
  background-position: center;

}

.cardContent {
  width: 100%;
  height: 100%;
}

.swipe:last-of-type {}

.card2 h3 {
  position: absolute;
  bottom: 0;
  margin: 10px;
  color: #FECB00 !important;
  font-size: 1.4em;
  font-weight: 600;
  background: white;
  padding: 5px 10px;
  border-radius: 3px;
}



.forswhiper {
  font-size: 2em;
  color: #fff;
  margin: 30px
}


.permenu {

  margin: 0 auto !important;
  display: block
}



.styles-module_close__2I1sI {
  color: white;
  position: absolute;
  top: 150px !important;
  right: 30px !important;
  font-size: 40px;
  font-weight: bold;
  opacity: 0.8 !important;
  cursor: pointer;
  z-index: 999999999;
}

.popup-overlay {
  position: absolute !important;


}

.popup-content {
  width: unset !important;
  border-radius: 3px;

}


.margino {
  margin-left: 10px;
}


.activity {

  display: flex;
  width: 100%;
  min-height: 60px;
  margin-bottom: 12px;
  border-radius: 3px;
  padding: 4px;
  cursor: pointer;



  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgb(155 155 155 / 20%);
  background: #fff;
  padding: 5px;
}


.pic-not {

  display: flex;
  width: 60px;
  height: 60px;
  border-radius: 3px;
  overflow: hidden;
  width: 50px;
  height: 50px;

}

.img-responsive {
  width: 100%;
  max-width: 100%;
}

.info-con {
  width: 85%;
  max-width: 100%;
  display: flex;
  flex-flow: column;
  padding-left: 15px;
  justify-content: center;
  color: #4a4a4a;
  cursor: pointer;
  text-align: left;
}

.info-con h3 {
  font-size: 1em !important;
  line-height: 1.5 !important;
  color: black !important;
  font-weight: 600
}

.info-con p {
  font-size: 0.8em !important;
  line-height: 1.5 !important;
}




.shad {

  box-shadow: 2px 2px 4px 4px rgb(155 155 155 / 20%);

}



.ribbon {
  padding: 1px 10px;
  background-color: #333;
  color: #fff;
  min-width: 100px;
  text-align: center;
  position: absolute;
  margin: 0;
  margin-top: 4px;
  font-size: 10px;
  display: inline-block;
  line-height: 1.5;
  left: 0;
  margin-bottom: 5px;
  top: -4px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  border: 0;
}

.ribbon:before {
  content: "";
  position: absolute;
  left: 100%;
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 13px solid transparent;
  border-top: 13px solid #333 !important;
  top: 0;
}

.ribbon:after {
  content: "";
  position: absolute;
  left: 100%;
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 13px solid #333 !important;
  bottom: 0;
}



.ribbon-selected {
  padding: 1px 10px;
  background-color: #FECB00;
  color: #fff;
  min-width: 100px;
  text-align: center;
  position: absolute;
  margin: 0;
  margin-top: 4px;
  font-size: 10px;
  display: inline-block;
  line-height: 1.5;
  left: 0;
  margin-bottom: 5px;
  top: -4px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  border: 0;
}

.ribbon-selected:before {
  content: "";
  position: absolute;
  left: 100%;
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 13px solid transparent;
  border-top: 13px solid #FECB00 !important;
  top: 0;
}

.ribbon-selected:after {
  content: "";
  position: absolute;
  left: 100%;
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 13px solid #FECB00 !important;
  bottom: 0;
}


.shaky-baby {
  animation: shake-animation 2.72s ease infinite;
  transform-origin: 50% 50%;
}

@keyframes shake-animation {
  0% {
    transform: translate(0, 0)
  }

  1.78571% {
    transform: translate(5px, 0)
  }

  3.57143% {
    transform: translate(0, 0)
  }

  5.35714% {
    transform: translate(5px, 0)
  }

  7.14286% {
    transform: translate(0, 0)
  }

  8.92857% {
    transform: translate(5px, 0)
  }

  10.71429% {
    transform: translate(0, 0)
  }

  100% {
    transform: translate(0, 0)
  }
}



.cs-message--outgoing .cs-message__content {
  color: #fff !important;
  background-color: #FD8092 !important;
  border-radius: 0.7em 0 0 0.7em;
}



.cs-message--incoming .cs-message__content {
  color: rgba(0, 0, 0, .87);
  background-color: #DCF3FF !important;
  border-radius: 0 0.7em 0.7em 0;
}

.cs-message-input__content-editor,
.cs-message-input__content-editor-wrapper {
  background-color: #DCF3FF !important;


}

.cs-message-input {
  padding-top: 10px !important;
}


.ovvvee:hover {
  opacity: 0.5;
}



@media screen and (min-width: 1024px) {

  .container>.navbar .navbar-brand,
  .navbar>.container .navbar-brand {
    margin-left: 0rem !important;
  }


}


/*
.flag-select__option {
  padding: 0px 4px !important;

}

.flag-select__btn {
  padding: 10px 0px !important;
  color: #3c2200 !important;
  font-size: 1em !important;
  font-weight: 600;
}

.flag-select{

  padding:0 !important;

}

*/


#nav-toggle-state:checked~.navbar-menu {
  background-color: #FECB00 !important;

}


.legend {
  background-color: #FECB00 !important;

}


.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 2px solid #FECB00;
}

.separator:not(:empty)::before {
  margin-right: .35em;
}

.separator:not(:empty)::after {
  margin-left: .35em;
}



.accordion__button {
  background-color: #FECB00 !important;
  border-color: #FECB00 !important;
  color: #000 !important
}

.control.has-icons-left .icon,
.control.has-icons-right .icon {
  height: 3em
}

button:hover {

  background-color: #f0e67b !important;


}


button {
  cursor: pointer !important;
}

.custom-loader {
  height: 16px;
  background:
    linear-gradient(#FECB00 0 0),
    linear-gradient(#FECB00 0 0),
    #E4E4ED;
  background-size: 60% 100%;
  background-repeat: no-repeat;
  animation: p6 3s infinite;
  margin: 20px 0px;
}

@keyframes p6 {
  0% {
    background-position: -150% 0, -150% 0
  }

  66% {
    background-position: 250% 0, -150% 0
  }

  100% {
    background-position: 250% 0, 250% 0
  }
}


.scrollable-div {
  height: 300px;
  /* Defined height */
  overflow-y: scroll;
  background-color: #fdc4ff;
  /* Background color */
  color: #000000;
  /* Font color */
  font-style: italic;
  /* Italic text */
  padding: 10px 30px;
  border-radius: 10px;
  /* Optional padding for better readability */
}

.scrollable-div::-webkit-scrollbar {
  width: 16px;
  /* Thick scrollbar */
}

.scrollable-div::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color of the scrollbar */
  border-radius: 10px;
  /* Rounded edges for the scrollbar */
}

.scrollable-div::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Background of the scrollbar track */
}



.wrapperan {
  /*height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#eee;
  */
}

.animated-check {
  height: 10em;
  width: 10em
}

.animated-check path {
  fill: none;
  stroke: #FECB00;
  stroke-width: 4;
  stroke-dasharray: 23;
  stroke-dashoffset: 23;
  animation: draw 1s linear forwards;
  stroke-linecap: round;
  stroke-linejoin: round
}

@keyframes draw {
  to {
    stroke-dashoffset: 0
  }
}



/* EditorStyles.css */
.demo-wrapper {
  background: #fff;
  /* Ensure the background is white */
  color: #000;
  /* Ensure the text color is black */
}

.demo-editor {
  background: #fff;
  /* Ensure the background is white */
  color: #000 !important;
  /* Ensure the text color is black */
}

.rdw-editor-main {
  color: #000 !important;
  /* Ensure the text color is black */
}

/* Ensure that all text inside the editor uses black as the default color */
.rdw-editor-main h1,
.rdw-editor-main h2,
.rdw-editor-main h3,
.rdw-editor-main h4,
.rdw-editor-main h5,
.rdw-editor-main h6,
.rdw-editor-main p,
.rdw-editor-main span,
.rdw-editor-main li {
  color: #000 !important;
}




.container2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
}

.box2 {

  padding: 20px;
  text-align: center;
  flex: 1;
  margin: 0 10px;

  color: black;
  padding: 10px !important;
  border: 1px solid #D7D7D7;
  border-radius: 10px;
  box-shadow: 10px 10px 60px -16px rgba(0, 0, 0, 0.45);




}

/* Optional: to make the boxes the same size and maintain the spacing */
.box2:first-child {
  margin-left: 0;
}

.box2:last-child {
  margin-right: 0;
}


.my-data-table .rdt_TableHeader {
  padding: 0 !important;
}

/* Optionally, you can add styles to the subHeaderComponent */
.my-data-table .rdt_TableHeader>div {
  padding: 0 !important;
}

.iIzCTJ {
  padding-left: 15px !important;
}


.react-daterange-picker,
.react-daterange-picker *,
.react-daterange-picker *:before,
.react-daterange-picker *:after {

  color: #000;

}

.react-daterange-picker__wrapper {
  background-color: #fff !important;
  padding: 10px;
  border-radius: 10px;

}

.editor-wrapper {
  border: 1px solid #ddd;
  position: relative;
  height: 400px;
  /* Set your desired height */
  display: flex;
  flex-direction: column;
}

.editor-toolbar {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 10;
  border-bottom: 1px solid #ddd;
}

.editor-main-wrapper {
  flex: 1;
  overflow: hidden;
  /* Prevent scrolling on this wrapper */
  display: flex;
  flex-direction: column;
}

.editor-content {
  flex: 1;
  overflow-y: auto;
  /* Make this div scrollable */
  padding: 10px;
  box-sizing: border-box;
  /* Ensure padding doesn't add to the height */
}



.flex-container {
  display: flex;
  justify-content: flex-end;
  /* This places items with space between them */
  align-items: center;
  /* This vertically centers the items */
  padding: 10px;
}


.right-button {
  margin-left: 10px;
  /* Optional: Adds some space between the content and the button */

}




.button-container2 {
  display: flex;
  justify-content: center;
  align-items: center;

}

nav {
  box-shadow: unset
}

input.radiode {
  display: none;

  &:not(:disabled)~label.labelde {
    cursor: pointer;
  }

  &:disabled~label.labelde {
    color: hsla(150, 5%, 75%, 1);
    border-color: hsla(150, 5%, 75%, 1);
    box-shadow: none;
    cursor: not-allowed;
  }
}

.labelde {
  height: 100%;
  display: block;
  background: white;
  border: 2px solid #FECB00;
  border-radius: 20px;
  padding: 1rem;
  margin-bottom: 1rem;
  margin: 1rem;
  text-align: center;
  box-shadow: 0px 3px 10px -2px hsla(150, 5%, 65%, 0.5);
  position: relative;
  color: black;

}

input[type="radio"]:checked+label {
  background: #FECB00;
  color: black;
  box-shadow: 0px 0px 20px #FECB00;
  position: relative;
  padding: 30px;
}

input[type="radio"]:checked+label::after {
  content: "✔";
  /* HTML entity for checkmark */
  font-size: 24px;
  color: hsla(215, 5%, 25%, 1);
  border: 2px solid #FECB00;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  background: white;
  box-shadow: 0px 2px 5px -2px hsla(0, 0%, 0%, 0.25);
}


.billingt {

  display: flex;
  flex-direction: column;
  align-items: center;
}

.billingt2 {
  width: 400px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #FECB00;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 26px;
  height: 26px;
  background: #FECB00;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  transition: all 0.2s ease;
}

input[type="range"]::-webkit-slider-thumb:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(254, 203, 0, 0.3);
}

input[type="range"]::-moz-range-thumb {
  width: 26px;
  height: 26px;
  background: #FECB00;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  transition: all 0.2s ease;
}

input[type="range"]::-moz-range-thumb:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(254, 203, 0, 0.3);
}